import React, { useEffect } from "react";
import { useState } from "react";
import axios from 'axios';
import { toast} from 'react-toastify';
import $ from 'jquery'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Preview } from "../../Preview/Preview";

import emailjs from 'emailjs-com';

var AWS = require('aws-sdk');

const Step4 = props => {

  const cancelRequest = async () => {
    await props.cancelToken.cancel()
    document.getElementById("myForm").reset();
    props.setFileName('')
    props.setUrl('')
    props.previousStep()
  }

  useEffect(() => {
    if(props.loaded === 100){
      props.nextStep()
    }
  }, [props.loaded])

  return (
    <>
      <div class="container col-md-10 col-sm-12 pt-5">
          <h1 class="text-center">UPLOADING</h1> 
          <div className="text-center">
            <div id="percent" className="percent animate__animated animate__pulse animate__infinite">{props.loaded}<span>%</span></div>
            {/* <i className="fa fa-spinner fa-spin"></i> */}
            <p> {props.fileSize} of {props.fileTotal}</p>
          </div>
        <button onClick={()=> cancelRequest()} className="w-100 btn-md btn btn-cancel">CANCEL</button>
      </div>
    </>
  );
};

export default Step4;
