import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import FileUploaderTransfer from "../../Uploader/FileUploaderTransfer";
import axios from 'axios';

import {isMobile, isIOS} from 'react-device-detect';


const Step3 = props => {

  const [load, setLoad] = useState(false)

  useEffect(() => {
    if(props.isActive){
      setTimeout(() => {
        setLoad(true)
      }, 1000);
    } else {
      setLoad(false)
    }
  }, [props.isActive])



  const deleteS3 = () =>{
    console.log(props.arrayMultiple)
    for (let index = 0; index < props.arrayMultiple.length; index++) {
        const element = props.arrayMultiple[index];
        // console.log(element)
        axios({
            method: 'post',
            url: 'https://ft.yamagroup.com/deleteObj',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            data: {
              url: element
            }
          })
          .then((response) => {
            // console.log(response);
            // console.log(`DELETED`)
            
          }, (error) => {
          });
    }
    props.setArrayMultiple([])
    props.setFilesMoreThanOne(false)
    props.setLoadingFilesAll(0)
    props.previousStep()
  }

  const goBack = () => {

    if(props.arrayMultiple.length > 0){
      console.log("Tengo archivos")
      let txt;
      let r = window.confirm("If you go back, you will lose the current files");
      if (r == true) {
        txt = deleteS3()
      } else {
        txt = "";
      }
    } else {
      props.previousStep()
    }
  }

  return (
    <>
      { load ? 
        <button type="button" class="btn btn-back btn-link mx-auto w-0 back animate__animated animate__fadeInRight" onClick={()=>goBack()}><i class="bi bi-chevron-left"></i></button>
        : ''}
      <div class="container col-md-10 col-sm-12 pt-5 ">
          <h1 class="text-center">STEP 2 </h1>
          <p class="text-center">Now it's time to select the file you want to upload. Just drag and drop or click in the icon below</p>
        <FileUploaderTransfer
          setDeleteArray={props.setDeleteArray}
          deleteArray={props.deleteArray}
          loadingFilesAll={props.loadingFilesAll}
          setLoadingFilesAll={props.setLoadingFilesAll}
          filesMoreThanOne={props.filesMoreThanOne}
          setFilesMoreThanOne={props.setFilesMoreThanOne}
          arrayMultiple={props.arrayMultiple}
          setArrayMultiple={props.setArrayMultiple}
          cancelToken={props.cancelToken}
          setCancelToken={props.setCancelToken}
          setLoaded={props.setLoaded}
          onSuccess={props.onSuccess}
          name={props.name}
          nextStep={props.nextStep}
          goToStep={props.goToStep}
          setFileName={props.setFileName}
          setFileSize={props.setFileSize}
          setFileTotal={props.setFileTotal}
        />
        { isMobile && isIOS ? <p class="text-center iOSdescription"> If you are uploading a HQ video from iOS device, make sure to select it from your Photo Library.</p> : null} 
      </div>
    </>
  );
};

export default Step3;
