import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css'

const NotFound = () => (
  <div id="not-found" className="walkthrough show reveal">
    <div id="not-found-container">
        <h1>404</h1>
        <h3>Not Found</h3>
    </div>
    <Link to="/">Go Home</Link>
  </div>
);

export default NotFound;