import { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Wizard } from './Components/Wizard/Wizard';
import { WizardTransfer } from './Components/Wizard/WizardTransfer';
import FileUploaderInternal from './Components/Uploader/FileUploaderInternal';
import { Modal } from './Components/Modal/Modal';
import NotFound from './Components/404/NotFound';
import 'animate.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

import $ from 'jquery'

import logo from '../src/Assets/logo.png';

import ReactGA from 'react-ga';


function App() {
  
    const trackingId = "UA-222100383-1";
    ReactGA.initialize(trackingId);

    const [prevSteps, setPrevSteps] = useState(false)
    const [display, setDisplay] = useState('none')
    const [displayModal, setDisplayModal] = useState('block')

    let history = useHistory()

  useEffect(() => {
    var randomArray = [
      'bg1','bg2', 'bg3'
    ];

      var random = randomArray[Math.floor(Math.random() * randomArray.length)];
      // console.log(`RANDOM`, random)
      var element = document.getElementById("wrapper");
      element.classList.add(random);
  }, [])
  
  useEffect(() => {
    
    $(document).ready(function () {
      
    })

    if(!prevSteps){
      $('.login-wrapper').hide();
      $(document).ready(function(){
        $('.login-wrapper').fadeIn(1500);
      });
    }

    if(prevSteps){
      setDisplay('block')
      setDisplayModal('none')
      $('.login-container').removeClass('hidden').addClass('showContainer');
      $('.modal').addClass('hidden');
    }
  }, [prevSteps])

  return (
    <main>
        <div id="wrapper" class="login-wrapper hide" >
        <>
          <Router>
          <Switch>
              <Route exact path="/internal">
                <div className="login-container bg-white showContainer" >
                  <div className="container col-12">
                    <div class="logo-container mt-4 text-center"><img className="logo" src={logo} width="90" /></div>
                      <FileUploaderInternal className="wizard" id="wizard" />
                  </div>
                </div>
              </Route>
              <Route exact path="/">
                <div className="login-container bg-white showContainer" >
                  <div className="container col-12">
                    <div class="logo-container mt-4 text-center"><img className="logo" src={logo} width="90" /></div>
                      <WizardTransfer className="wizard" id="wizard" />
                  </div>
                </div>
              </Route>
              <Route exact path="/videos">
                <Modal setPrevSteps={setPrevSteps} className="modal"/>
                <div className="login-container bg-white hidden" >
                  <div className="container col-12">
                    <div class="logo-container mt-4 text-center"><img className="logo" src={logo} width="90" /></div>
                      <Wizard className="wizard" id="wizard" />
                  </div>
                </div>
              </Route>
              <Route component={NotFound} />
              </Switch>
          </Router>
        </>
      </div>
    </main>
  );
}

export default App;
