import { useState } from "react";

import StepWizard from "react-step-wizard";
import Step1 from "./StepsTransfer/Step1";
import Step2 from "./StepsTransfer/Step2";
import Step3 from "./StepsTransfer/Step3";
import Step4 from "./StepsTransfer/Step4";
import Step5 from "./StepsTransfer/Step5";
import Step6 from "./StepsTransfer/Step6";

export const WizardTransfer = props => {

    const [name, setName] = useState('')
    const [fileName, setFileName] = useState('')
    const [loaded, setLoaded] = useState(0)
    const [cancelToken, setCancelToken] = useState()

    const [fileSize, setFileSize] = useState(0)
    const [fileTotal, setFileTotal] = useState(0)

    const [url, setUrl] = useState('')

    const [filesMoreThanOne, setFilesMoreThanOne] = useState(false)
    const [arrayMultiple,setArrayMultiple] = useState([]);
    const [deleteArray,setDeleteArray] = useState([]);

    const [loadingFilesAll,setLoadingFilesAll] = useState();

    // console.log(`cancelToken`, cancelToken)

    return(
        <StepWizard initialStep={1}>
            {/* <Step1 stepName={"INFO"}/> */}
            <Step2 
                stepName={"USER"}
                name={name}
                setName={setName}
            />
            <Step3 
                stepName={"CONTENT"}
                deleteArray={deleteArray}
                setDeleteArray={setDeleteArray}
                setLoadingFilesAll={setLoadingFilesAll}
                loadingFilesAll={loadingFilesAll}
                cancelToken={cancelToken}
                filesMoreThanOne={filesMoreThanOne}
                setFilesMoreThanOne={setFilesMoreThanOne}
                arrayMultiple={arrayMultiple}
                setArrayMultiple={setArrayMultiple}
                onSuccess={fileName}
                name={name}
                setFileName={setFileName}
                setLoaded={setLoaded}
                setCancelToken={setCancelToken}
                setFileSize={setFileSize}
                setFileTotal={setFileTotal}
            />
            <Step4 
                stepName={"LOADING"}
                name={name}
                deleteArray={deleteArray}
                setDeleteArray={setDeleteArray}
                arrayMultiple={arrayMultiple}
                setArrayMultiple={setArrayMultiple}
                setLoadingFilesAll={setLoadingFilesAll}
                loadingFilesAll={loadingFilesAll}
                filesMoreThanOne={filesMoreThanOne}
                setFilesMoreThanOne={setFilesMoreThanOne}
                url={url}
                setUrl={setUrl}
                setFileName={setFileName}
                fileName={fileName}
                loaded={loaded}
                cancelToken={cancelToken}
                name={name}
                fileSize={fileSize}
                fileTotal={fileTotal}
            />
            <Step5 
                stepName={"PREVIEW"}
                url={url}
                setUrl={setUrl}
                setFileName={setFileName}
                fileName={fileName}
                loaded={loaded}
                cancelToken={cancelToken}
                name={name}
                fileSize={fileSize}
                fileTotal={fileTotal}
            />
            <Step6 
                stepName={"THANKS"}
                arrayMultiple={arrayMultiple}
                setArrayMultiple={setArrayMultiple}
                filesMoreThanOne={filesMoreThanOne}
                setFilesMoreThanOne={setFilesMoreThanOne}
                url={url}
                setUrl={setUrl}
                setFileName={setFileName}
            />
        </StepWizard>
    )
};