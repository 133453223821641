import React from "react";
import './style.css';
import axios from 'axios';
import { toast} from 'react-toastify';
import $ from 'jquery'
import 'animate.css';

import emailjs from 'emailjs-com';

import { Preview } from "../../Preview/Preview";

const Step5 = props => {

  // console.log(`url`, props.fileName)

  const deleteConfirmation = () => {
    // OLD ALERT CONFIRMATION

    // confirmAlert({
    //   title: 'Are you sure you want to go back? ',
    //   message: 'If you go back, you may upload another file.',
    //   buttons: [
    //     {
    //       label: 'Yes',
    //       onClick: () => deleteS3()
    //     },
    //     {
    //       label: 'No',
    //       onClick: () => console.log("clicked no")
    //     }
    //   ]
    // });

    let txt;
    let r = window.confirm("If you go back, you will lose the current file");
    if (r == true) {
      txt = deleteS3();
    } else {
      txt = "";
    }
  }

  

  const deleteS3 = () =>{
      axios({
        method: 'post',
        url: 'https://ft.yamagroup.com/deleteObj',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          url: props.fileName
        }
      })
      .then((response) => {
        // console.log(response);
        // console.log(`DELETED`)
        
      }, (error) => {
        // console.log(error);
      });
      document.getElementById("myForm").reset();
      props.setFileName('')
      props.goToStep(2)
  }

  const sendEmail = async () => {
    const data = {
      name: props.name,
      fileName: props.fileName 
      
    }

    
    await emailjs.send('service_dtmicm9', 'template_b7f7ter', data, 'user_vLr8jShytmqnkQO7rr0yC')
      .then((result) => {
          console.log('success email sent')//This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          // console.log(error.text);
      });
      props.setFileName('')
      props.nextStep()
  }

  return (
    <>
    <button type="button" class="btn btn-back btn-link mx-auto w-0 back animate__animated animate__fadeInRight" onClick={()=>deleteConfirmation()}><i class="bi bi-chevron-left "></i></button>
    <div class="container col-md-10 col-sm-12 pt-5 ">
        <h1 class="text-center">PREVIEW</h1>
        <p class="text-center">Almost done!. How does it look?</p>
        <Preview fileName={props.fileName} loaded={props.loaded} setUrl={props.setUrl} fileSize={props.fileSize} fileTotal={props.fileTotal}/>
        <div className="mb-4">
          <button onClick={()=> deleteConfirmation()} style={{ width: '45%', marginRight: '5%'}} className=" btn-md btn btn-cancel">CANCEL</button>
          <button className=" btn-md btn btn-next" style={{ width: '45%', marginLeft: '5%'}}  onClick={()=>sendEmail()}>SUBMIT</button>
        </div>
      </div>
    </>
  );
};

export default Step5;
