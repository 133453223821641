import React, { useEffect } from 'react'

import styles from './index.css'
import $ from 'jquery'

export const Modal = ({setPrevSteps}) => { 

  useEffect(() => {
    (function() {
      $(document).ready(function() {
        var walkthrough;
        walkthrough = {
          index: 0,
          nextScreen: function() {
            if (this.index < this.indexMax()) {
              this.index++;
              return this.updateScreen();
            }
          },
          prevScreen: function() {
            if (this.index > 0) {
              this.index--;
              return this.updateScreen();
            }
          },
          updateScreen: function() {
            this.reset();
            this.goTo(this.index);
            return this.setBtns();
          },
          setBtns: function() {
            var $lastBtn, $nextBtn, $prevBtn;
            $nextBtn = $('.next-screen');
            $prevBtn = $('.prev-screen');
            $lastBtn = $('.finish');
            if (walkthrough.index === walkthrough.indexMax()) {
              $nextBtn.prop('disabled', true);
              $prevBtn.prop('disabled', false);
              return $lastBtn.addClass('active').prop('disabled', false);
            } else if (walkthrough.index === 0) {
              $nextBtn.prop('disabled', false);
              $prevBtn.prop('disabled', true);
              return $lastBtn.removeClass('active').prop('disabled', true);
            } else {
              $nextBtn.prop('disabled', false);
              $prevBtn.prop('disabled', false);
              return $lastBtn.removeClass('active').prop('disabled', true);
            }
          },
          goTo: function(index) {
            $('.screen').eq(index).addClass('active');
            return $('.dot').eq(index).addClass('active');
          },
          reset: function() {
            return $('.screen, .dot').removeClass('active');
          },
          indexMax: function() {
            return $('.screen').length - 1;
          },
          closeModal: function() {
            $('.walkthrough, .shade').removeClass('reveal');
            return setTimeout(((function(_this) {
              return function() {
                $('.walkthrough, .shade').removeClass('show');
                $('.open-walkthrough').addClass('hiddenModal');
                _this.index = 0;
                console.log('cierro')
                return setPrevSteps(true)
              };
            })(this)), 200);
            
          },
          openModal: function() {
            $('.walkthrough, .shade').addClass('show');
            setTimeout(((function(_this) {
              return function() {
                return $('.walkthrough, .shade').addClass('reveal');
              };
            })(this)), 2000);
            return this.updateScreen();
          }
        };
        $('.next-screen').click(function() {
          return walkthrough.nextScreen();
        });
        $('.prev-screen').click(function() {
          return walkthrough.prevScreen();
        });
        $('.close').click(function() {
          return walkthrough.closeModal();
        });
        $('.skip').click(function() {
          return walkthrough.closeModal();
        });
          return walkthrough.openModal();
      });
    }).call(this);
  }, [])

  return (
    <>
    {/* <!--/ A set of walkthrough screens in HTML/CSS/JS. A personal experiment with layering images, CSS3 transitions, & flexbox. --> */}
    {/* <button class="open-walkthrough">Start</button> */}
    <div class="walkthrough show ">
      {/* <button class="btn skip">Skip</button> */}
      <div class="walkthrough-pagination">
        <a class="dot active"></a>
        <a class="dot"></a>
        <a class="dot"></a>
        <a class="dot"></a>
        <a class="dot"></a>
      </div>
      <div class="walkthrough-body">
        <ul class="screens animate">
          <li class="screen active">
            <div class="media logo">
              <img class="logo" src="https://yamalabs.com/mail-signature/yama-logo-signature-iso-2021.png"/>
            </div>
            <h3>
              Welcome to the
              <br/>YAMA video uploader! 
            </h3>
            <p>An easy way to upload and share your videos with us.<br/>To make your video reach the next production level, please make sure that you have followed the steps on the recording guidelines. </p>
          </li>
          <li class="screen">
            <div class="media walk">
              <img class="icon" src="https://yamalabs.com/dev/icons/002.png"/>
            </div>
            <h3>
              Your
              <br/>
            Location
            </h3>
            <p>Make sure that your video wasn't recorded with yourself back facing a window.</p>
          </li>
          <li class="screen">
            <div class="media walk">
              <img class="icon" src="https://yamalabs.com/dev/icons/005.png"/>
            </div>
            <h3>
              Video
              <br/>
              Settings
            </h3>
            <p>Confirm that your video was recorded with the settings: 1920 x 1080 resolution at 30 FPS</p>
          </li>
          <li class="screen">
            <div class="media walk">
              <img class="icon" src="https://yamalabs.com/dev/icons/004.png"/>
            </div>
            <h3>
              Audio
              <br/>
              Settings
            </h3>
            <p>Watch out! Does your video was recorded in a noise-free environment?</p>
          </li>
          <li class="screen">
            <div class="media walk">
              <img class="icon" src="https://yamalabs.com/dev/icons/006.png"/>
            </div>
            <h3>
              You are
              <br/>
              Ready!
            </h3>
            <p>If you met all the previous requirements, you are ready to upload your video!</p>
          </li>
        </ul>
        <button class="prev-screen">
          <i class="icon-angle-left"></i>
        </button>
        <button class="next-screen">
          <i class="icon-angle-right"></i>
        </button>
      </div>
      <div class="walkthrough-footer">
        <button class="button next-screen">Next</button>
        <button class="button finish close" disabled="true">Let's GO</button>
      </div>
    </div>
  </>
  )
}

export default Modal;
