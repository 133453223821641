import { useState } from "react";

import StepWizard from "react-step-wizard";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import Step6 from "./Steps/Step6";

export const Wizard = props => {

    const [name, setName] = useState('')
    const [fileName, setFileName] = useState('')
    const [loaded, setLoaded] = useState(0)
    const [cancelToken, setCancelToken] = useState()

    const [fileSize, setFileSize] = useState(0)
    const [fileTotal, setFileTotal] = useState(0)

    const [url, setUrl] = useState('')

    const [filesMoreThanOne, setFilesMoreThanOne] = useState(false)
    const [arrayMultiple,setArrayMultiple] = useState([]);

    // console.log(`cancelToken`, cancelToken)

    return(
        <StepWizard initialStep={1}>
            {/* <Step1 stepName={"INFO"}/> */}
            <Step2 
                stepName={"USER"} 
                name={name}
                setName={setName}
            />
            <Step3 
                stepName={"CONTENT"}
                cancelToken={cancelToken}
                filesMoreThanOne={filesMoreThanOne}
                setFilesMoreThanOne={setFilesMoreThanOne}
                arrayMultiple={arrayMultiple}
                setArrayMultiple={setArrayMultiple}
                onSuccess={fileName}
                name={name}
                setFileName={setFileName}
                setLoaded={setLoaded}
                setCancelToken={setCancelToken}
                setFileSize={setFileSize}
                setFileTotal={setFileTotal}
            />
            <Step4 
                stepName={"LOADING"}
                url={url}
                setUrl={setUrl}
                setFileName={setFileName}
                fileName={fileName}
                loaded={loaded}
                cancelToken={cancelToken}
                name={name}
                fileSize={fileSize}
                fileTotal={fileTotal}
            />
            <Step5 
                stepName={"PREVIEW"}
                url={url}
                setUrl={setUrl}
                setFileName={setFileName}
                fileName={fileName}
                loaded={loaded}
                cancelToken={cancelToken}
                name={name}
                fileSize={fileSize}
                fileTotal={fileTotal}
            />
            <Step6 
                stepName={"THANKS"}
                url={url} 
                setUrl={setUrl}
                setFileName={setFileName}
            />
        </StepWizard>
    )
};