import React from "react";
import ReactPlayer from 'react-player'

import 'font-awesome/css/font-awesome.min.css';
import { useEffect } from "react";
import 'animate.css';

import './style.css';
import { render } from "react-dom/cjs/react-dom.development";

export const Preview = ({fileName, loaded, setUrl, fileSize, fileTotal}) => {

    // console.log(`PREVIEW FILES...`, fileName)
    // console.log(`onUploading`, loaded)
    

    var url = `https://yama-uploader.s3.amazonaws.com/${fileName}`

    useEffect(() => {
        setUrl(url)
    }, [fileName]) 
    
    return (
        <div className='player-wrapper mb-4 pt-4 text-center'>
            <ReactPlayer
            id="videoPlayer"
            url={url}
            muted={true}
            autoplay
            playing
            preload="true"
            width='100%'
            height='100%'
            loop={true}
            controls={true}
            config={{
                file: {
                playerVars: { showinfo: 1 }
                },
                }}
            />
        </div>
    )
};

export default Preview;