import React from "react";
import './style.css';

const Step6 = props => {

  const uploadAnother = () => {
    document.getElementById("myForm").reset();
    props.setFileName('')
    props.goToStep(2)
  }

  return (
    <div class="container col-md-10 col-sm-12 pt-5 ">
      <h1 class="text-center">THANK YOU!</h1>
      <p class="text-center">Have a nice day. Your file is safe with us.</p>
      <button className="w-100 btn-md btn btn-next" onClick={()=>uploadAnother()}>UPLOAD ANOTHER FILE</button>
    </div>
  );
};

export default Step6;
