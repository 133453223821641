import React, { useEffect } from "react";
import { useState } from "react";
import axios from 'axios';
import { toast} from 'react-toastify';
import $ from 'jquery'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Preview } from "../../Preview/Preview";

import emailjs from 'emailjs-com';

import ReactGA from 'react-ga';

var AWS = require('aws-sdk');

const Step4 = props => {

  const cancelRequest = async () => {
    await props.cancelToken.cancel()
    document.getElementById("myForm").reset();
    props.setFileName('')
    props.setUrl('')
    props.setDeleteArray([])
    props.setArrayMultiple([])
    deleteS3();
    props.goToStep(2)
    props.setLoadingFilesAll(0)
  }

  useEffect(() => {
    if(props.loadingFilesAll === 100){
      sendEmail();
      props.goToStep(5)
    }
  }, [props.loadingFilesAll])


  const deleteS3 = () =>{
    console.log(props.deleteArray)
    for (let index = 0; index < props.deleteArray.length; index++) {
        const element = props.deleteArray[index];
        // console.log(element)
        axios({
            method: 'post',
            url: 'https://ft.yamagroup.com/deleteObj',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
            data: {
              url: element
            }
          })
          .then((response) => {
            // console.log(response);
            // console.log(`DELETED`)
            
          }, (error) => {
          });
    }
    props.setDeleteArray([])
    props.setFilesMoreThanOne(false)
    props.setLoadingFilesAll(0)
    props.previousStep()
  }


  const sendEmail = async () => {
    // console.log(props.deleteArray.length)

      ReactGA.event({
        category: 'Email',
        action: 'call', 
        label: `-`,
      })

    if(props.deleteArray.length > 1){
      var arrayMailHtml = ""

      props.deleteArray.map(x =>{
          arrayMailHtml = arrayMailHtml + "<a href=https://yama-uploader.s3.amazonaws.com/"+encodeURI(x)+">"+x+"</a><br/><br/>"
          // console.log(arrayMailHtml)
      })
      // console.log(arrayMailHtml)
      const data = {
        name: props.name,
        fileName: arrayMailHtml
      }
      
      await emailjs.send('service_dtmicm9', 'template_g3ma1ej', data, 'user_vLr8jShytmqnkQO7rr0yC')
        .then((result) => {
            console.log('success email sent')//This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
            ReactGA.event({
              category: 'Submited',
              action: 'Sent_Ok Multiple', 
              label: `-`,
          })
        }, (error) => {
            // console.log(error.text);
            let errorStringMultiple = error.text.toString();
            ReactGA.event({
              category: 'Submited',
              action: 'Sent_Error Multiple', 
              label: `Error: ${errorStringMultiple}`,
          })
        });

    } else {

      const data = {
        name: props.name,
        fileName: props.deleteArray[0]

      }
      
      await emailjs.send('service_dtmicm9', 'template_b7f7ter', data, 'user_vLr8jShytmqnkQO7rr0yC')
        .then((result) => {
            console.log('success email sent')//This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
            ReactGA.event({
              category: 'Submited',
              action: 'Sent_Ok', 
              label: '-',
          })
        }, (error) => {
            console.log(error.text);
            let errorString = error.text.toString();
            ReactGA.event({
              category: 'Submited',
              action: 'Sent_Error', 
              label: `Error: ${errorString}`,
          })
        });
    }
  }

  return (
    <>
      <div class="container col-md-10 col-sm-12 pt-5">
          <h1 class="text-center">UPLOADING</h1> 
          <div className="text-center">
            <div id="percent" className="percent animate__animated animate__pulse animate__infinite">{props.loadingFilesAll}<span>%</span></div>
            <p> {props.fileSize} of {props.fileTotal}</p>
          </div>
          { props.loadingFilesAll !== 100 ?
            <button onClick={()=> cancelRequest()} className="w-100 btn-md btn btn-cancel">CANCEL</button>
          :
            ''
          }
      </div>
    </>
  );
};

export default Step4;
