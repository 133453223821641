import {useEffect} from 'react';
import axios from 'axios';
import { toast} from 'react-toastify';
import $  from 'jquery'

import './style.css';
import { useState } from 'react';

import emailjs from 'emailjs-com';

import ReactGA from 'react-ga';

import {CopyToClipboard} from 'react-copy-to-clipboard';

export const FileUploaderInternal = ({ }) => {
    
    const [upload, setUpload] = useState(false);

    const [copied, setCopied] = useState(false)

    const [name, setName] = useState('')
    const [fileName, setFileName] = useState('')
    const [loaded, setLoaded] = useState(0)
    const [cancelToken, setCancelToken] = useState()

    const [fileSize, setFileSize] = useState(0)
    const [fileTotal, setFileTotal] = useState(0)

    const [showPercent, setShowPercent] = useState(false)
    const [showButtons, setShowButtons] = useState(false)

    const [url, setUrl] = useState('')

    const [filesMoreThanOne, setFilesMoreThanOne] = useState(false)
    const [arrayMultiple,setArrayMultiple] = useState([]);
    const [deleteArray,setDeleteArray] = useState([]);

    const [loadingFilesAll,setLoadingFilesAll] = useState();

    const [nameLink, setNameLink] = useState('')

    
    var files;

    var axiosConfig;

    useEffect(() => {
        files = '' ;
        setFileName('')
    }, [])

    
    const onInputChange = async(e) => {
        files = e.target.files;
        // console.log(e.target.files)
        // console.log(`file`, files)
        if(files !== ''){
            onSubmit(e)
        }
    };

    function formatSizeUnits(bytes){
        if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
        else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
        else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
        else if (bytes > 1)           { bytes = bytes + " bytes"; }
        else if (bytes == 1)          { bytes = bytes + " byte"; }
        else                          { bytes = "0 bytes"; }
        return bytes;
    }

    const cancelRequest = async () => {
        cancelToken.cancel()
        document.getElementById("myForm").reset();
        setFileName('')
        setFilesMoreThanOne(false)
        console.log(arrayMultiple)
        await deleteS3()
        setArrayMultiple([])
        setDeleteArray([])
    }

    const deleteS3 = () =>{
        console.log(deleteArray)
        for (let index = 0; index < deleteArray.length; index++) {
            const element = deleteArray[index];
            console.log(element)
            axios({
                method: 'post',
                url: 'https://ft.yamagroup.com/deleteObj',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                },
                data: {
                  url: element
                }
              })
              .then((response) => {
                // console.log(response);
                // console.log(`DELETED`)
                
              }, (error) => {
                console.log(error);
              });
        }
        document.getElementById("myForm").reset();
        setArrayMultiple([])
        setFilesMoreThanOne(false)

        setLoadingFilesAll(0)
    }

    const onSubmit = async (e) => {
        // e.preventDefault();

        setArrayMultiple([])
        setDeleteArray([])
        setLoaded(0)

        // console.log("FILES", files)
        for(let i = 0; i < files.length; i++) {
            var nameOfFileMultiple = files[i].name;
            encodeURI(nameOfFileMultiple)
            // console.log("URIIIIIII", nameOfFileMultiple)

            await setArrayMultiple(oldArray => [...oldArray,nameOfFileMultiple ] );
            await setDeleteArray(oldArray => [...oldArray,nameOfFileMultiple] );
            await setFilesMoreThanOne(true)
            await setShowButtons(true)
        }
    };

    const Upload = async() => {
        ReactGA.event({
            category: 'Upload',
            action: 'Uploading', 
            label: '-',
        })

        setShowButtons(false)

        setLoadingFilesAll(0)

        const selectedFile = document.getElementById('file').files;
        console.log(selectedFile)

        var data = new FormData();

        setArrayMultiple([])
        setDeleteArray([])
        
        for(let i = 0; i < selectedFile.length; i++) {
            var nameOfFileMultiple = selectedFile[i].name;
            setNameLink(nameOfFileMultiple)
            encodeURI(nameOfFileMultiple)

            await setArrayMultiple(oldArray => [...oldArray,nameOfFileMultiple] );
            await setDeleteArray(oldArray => [...oldArray,nameOfFileMultiple] );

            data.append('file', selectedFile[i], nameOfFileMultiple);
        }
        

        var cancelTokenSource = axios.CancelToken.source();
        setCancelToken(cancelTokenSource)

        axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            onUploadProgress: (ProgressEvent) => {
                const {loaded, total} = ProgressEvent

                const fileSize = formatSizeUnits(loaded)
                const fileTotal = formatSizeUnits(total)
                let percent = Math.floor( (loaded * 100) / total)
                setLoadingFilesAll(percent)
                setShowPercent(true)
                setLoaded(percent)
                setFileSize(fileSize)
                setFileTotal(fileTotal)
                // console.log(percent)
                // setLoadingFiles({...loadingFiles, percentFile: percent, nameFile: files[i].name})
            }, 
            cancelToken: cancelTokenSource.token
        };

        await axios.post('https://ft.yamagroup.com/uploadInternal', data, axiosConfig)
        .then(() => {
            toast.success('Upload Success');
            // onSuccess(fileName)
        })
        .catch((err) => {
            toast.error('Upload Error')
            if(axios.isCancel(err)){
                // console.log("I WAS CANCELED")
            }
        })
    }

    const Enter = (e) => {
        if(e.keyCode === 13){
          e.preventDefault();
        }
    }

    const removeFiles = () => {
        setArrayMultiple([])
        setDeleteArray([])
        setFilesMoreThanOne(false)
        document.getElementById("myForm").reset();
    }


    useEffect(() => {
        if(loaded == 100){
            setShowPercent(false)
            setFileSize(0)
            setFileTotal(0)
        }
    }, [loaded])


      function copyDivToClipboard() {

        setCopied(true)

        var divToCopy = document.getElementById('toClipboard');
        var textToCopy = '';
        
        // Recorre todos los elementos dentro del div
        for (var i = 0; i < divToCopy.childNodes.length; i++) {
          var node = divToCopy.childNodes[i];
          
          // Verifica si el nodo es un elemento <p>
          if (node.nodeName === 'P') {
            textToCopy += node.textContent + '\n'; // Agrega el texto del párrafo con un salto de línea
          }
        }
        
        var aux = document.createElement("textarea");
        aux.value = textToCopy;
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }


    return (
        <div class="container col-md-10 col-sm-12 pt-5 ">
          <h1 class="text-center">UPLOAD YOUR FILES</h1>
          <p class="text-center">Select the file/s you want to upload.<br/>Just drag and drop or click in the icon below</p>
           {!filesMoreThanOne ? 
                <form method="post" id="myForm" onSubmit={onSubmit}>
                    <div className="form-group files">
                        <input type="file"
                                id="file"
                            onChange={onInputChange}
                            className="form-control"
                            onKeyDown={(e) => Enter(e)}
                            multiple
                            />
                    </div>
                    {/* <button>Submit</button> */}
                </form>
            : 
                <form method="post" id="myForm" onSubmit={onSubmit} >
                    <div className="form-group files formDisabled">
                        <input type="file"
                                id="file"
                                disabled
                            onChange={onInputChange}
                            className="form-control"
                            accept="video/mp4,video/x-m4v,video/*,video/mov"
                            onKeyDown={(e) => Enter(e)}
                            multiple
                            />
                    </div>
                    {/* <button>Submit</button> */}
                </form>
            }

                <div className="div_files mt-5 mb-3">
                    {arrayMultiple.map( (element, id) => {
                        return ( 
                        <>  
                            <div>
                                <i class="bi bi-file-earmark"><p>{element}</p></i></div>
                            {/* {console.log(loadingFiles)} */}
                        </>)
                    })}
                    {showButtons && 
                        (<>
                            <button className="btn-md btn btn-cancel" style={{ width: '45%', marginRight: '5%'}} onClick={(e)=> {removeFiles(); e.preventDefault() }}>CANCEL</button>
                            <button className="btn-md btn btn-next" style={{ width: '45%', marginLeft: '5%'}} onClick={(e)=> {Upload(); e.preventDefault(); setUpload(true) }}>SUBMIT</button>
                        </>)
                    }

                    {
                        showPercent &&

                        <div className="text-center">
                            <div id="percent" className="percent animate__animated animate__pulse animate__infinite">{loaded}<span>%</span></div>
                            {/* <i className="fa fa-spinner fa-spin"></i> */}
                            <p> {fileSize} of {fileTotal}</p>
                        </div>
                    }


                    { loaded === 100 && 
                        <>
                            { arrayMultiple.length > 1 && 
                                <>
                                    <div className="link">
                                        <>
                                            {  arrayMultiple.map( (element, id) => {
                                                return  <><br/><a href={`https://ft.yamagroup.com/download/${element}`} target="_blank" style={{ backgroundColor: 'rgb(247 247 247)', display: 'inline-block', borderRadius: '10px',  boxShadow: 'rgba(10, 22, 50, 0.1) 0px 2px 8px -1px' , WebkitMaskImage: '-webkit-radial-gradient(center, white, black)', padding: '10px 14px', textAlign: 'center', textDecoration: 'none', fontFamily: 'arial, sans-serif', fontSize: '14px', color: '#ec3c96', marginBottom: '10px'}}>Download <i>{element}</i></a></>
                                            })}
                                        </>
                                    </div>
                                    <p> __ <br/> <br/> Your links are</p>
                                    <div id='toClipboard'>
                                        {  arrayMultiple.map( (element, id) => {
                                            return  <><p>{`https://ft.yamagroup.com/download/${element}`}</p></>
                                        })}
                                    </div>
                                    <a style={{ marginTop: '20px', fontSize: '14px', display: 'inline-block', textDecoration: 'none', cursor: 'pointer', color: '#0d6efd' }} onClick={() => copyDivToClipboard()}>Copy links to clipboard { copied ? <i class="bi bi-check-lg"></i> : null } </a>
                                    <p> <small><i>* Links are available for 30 days </i></small></p>
                                </>
                            }

                            { arrayMultiple.length == 1 && 
                                <>
                                    <div className="link">
                                        <>
                                            {  arrayMultiple.map( (element, id) => {
                                                return  <><br/><a href={`https://ft.yamagroup.com/download/${element}`} target="_blank" style={{ backgroundColor: 'rgb(247 247 247)', display: 'inline-block', borderRadius: '10px', boxShadow: 'rgba(10, 22, 50, 0.1) 0px 2px 8px -1px' , WebkitMaskImage: '-webkit-radial-gradient(center, white, black)', padding: '10px 14px', textAlign: 'center', textDecoration: 'none', fontFamily: 'arial, sans-serif', fontSize: '14px', color: '#ec3c96', marginBottom: '10px'}}>Download <i>{element}</i></a></>
                                            })}
                                        </>
                                    </div>
                                    <p> __ <br/> <br/> Your link is</p>
                                    <div id='toClipboard'>
                                        {  arrayMultiple.map( (element, id) => {
                                            return  <p>{`https://ft.yamagroup.com/download/${element}`}</p>
                                        })}
                                    </div>
                                    <a style={{ marginTop: '20px', fontSize: '14px', display: 'inline-block', textDecoration: 'none', cursor: 'pointer', color: '#0d6efd'  }} onClick={() => copyDivToClipboard()}>Copy link to clipboard { copied ? <i class="bi bi-check-lg"></i> : null } </a>
                                    <p> <small><i>* Link is available for 30 days </i></small></p>
                                </>
                            }
                        </>
                    }

                </div>
        </div>
    )
};

export default FileUploaderInternal;