import React, { useEffect } from "react";
import { useState , useContext} from "react";
import 'animate.css'
import $ from 'jquery'

import ReactGA from 'react-ga';
 
const Step2 = props => {

  const [load, setLoad] = useState(false)

  useEffect(() => {
    if(props.isActive){
      setTimeout(() => {
        setLoad(true)
      }, 1000);
    } else {
      setLoad(false)
    }
  }, [props.isActive])


  const nextPage = (e) => {
      if(e.keyCode === 13){
        e.preventDefault();
        if(props.name.trim() ){
          props.nextStep()
        }
      }
  }

  const ValidateInput = () => {
    if( props.name.trim() ) {
        props.nextStep()
        // ReactGA.event({
        //   category: 'Name',
        //   action: 'enter_name', 
        //   label: props.name,
        // })
    } else {
      $('#inputName').addClass('was-validated')
    }

  }

  const removeClass = () => {
    if(  $('#inputName').hasClass('was-validated')){
      $('#inputName').removeClass('was-validated')
    }
  }


  return (
    <>
      <div class="container col-md-10 col-sm-12 pt-5 ">
          <h1 class="text-center">STEP 1 </h1>
          <p class="text-center">Let's start by introducing yourself!</p>
          <form id="inputName" class="control col form-floating ">
              <input class="form-control input mt-3 mb-4" required="true" pattern="\S+" id="floatingInputValue" autoComplete="off" placeholder="Name" type="text" value={props.name} onChange={(e) => {props.setName(e.target.value); removeClass();}} onKeyDown={(e) => nextPage(e)}/><label for="floatingInputValue">Enter your name</label>
              <div class="invalid-feedback" style={{ marginTop: '-5%', marginBottom: '5%' }}>Please fill out this field.</div>
          </form>
          <button className="w-100 btn-md btn btn-next" onClick={() => ValidateInput()}>NEXT</button>
      </div>
    </>
  );
};

export default Step2;
