import React from "react";

import './style.css';

const Step1 = props => {

  return (
    <div class="container col-md-10 col-sm-12 pt-5 ">
        <h1>WELCOME</h1>
        <p>Let's start by introducing yourself!</p>
        {/* <form class="control col form-floating">
          <input class="form-control input mt-3 mb-4" id="floatingInputValue" placeholder="Name" type="text" value={props.name} onChange={(e) => props.setName(e.target.value)} /><label for="floatingInputValue">Enter your name</label>
        </form> */}
        <button className="w-100 btn-md btn-next" onClick={(e)=> {props.nextStep(); e.preventDefault() }}>NEXT</button>
    </div>
  );
};

export default Step1;
