import React, { useEffect } from "react";
import { useState , useContext, useRef} from "react";
import 'animate.css';

import $ from 'jquery'

const Step2 = props => {

  const [load, setLoad] = useState(false)

  const buttonRef = useRef(null);

  useEffect(() => {
    if(props.isActive){
      setTimeout(() => {
        setLoad(true)
      }, 1000);
    } else {  
      setLoad(false)
    }

    buttonRef.current.disabled = false;
  }, [props.isActive])


  const nextPage = (e) => {
      if(e.keyCode === 13){
        e.preventDefault();
        if(props.name.trim() ){
          props.nextStep()
        }
      }
  }

  const ValidateInput = (event) => {
    // if( props.name.trim() ) {
        props.nextStep()
    // } else {
    //   $('#inputName').addClass('was-validated')
    // }

    buttonRef.current.disabled = true;
  }

  const removeClass = () => {
    if(  $('#inputName').hasClass('was-validated')){
      $('#inputName').removeClass('was-validated')
    }
  }

  return (
    <>
      <div class="container col-md-10 col-sm-12 pt-5 ">
          <h1 class="text-center">STEP 1 </h1>
          <p class="text-center">Let's start by introducing yourself!</p>
          <form id="inputName" class="control col form-floating ">
              <input 
                class="form-control input mt-3 mb-4" 
                required="true" 
                pattern="\S+" 
                id="floatingInputValue" 
                autoComplete="off" 
                placeholder="Name" 
                type="text" 
                value={props.name} 
                onChange={(e) => {props.setName(e.target.value); removeClass();}} 
                onKeyDown={(e) => nextPage(e)}
              />
              <label for="floatingInputValue">Enter your name</label>
              <div class="invalid-feedback" style={{ marginTop: '-5%', marginBottom: '5%' }}>Please fill out this field.</div>
          </form>
          <button className="w-100 btn-md btn btn-next" ref={buttonRef} onClick={(event) => ValidateInput(event)}>NEXT</button>
      </div>
    </>
  );
};

export default Step2;
